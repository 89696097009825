.container-images {
 display: flex;
 flex-direction: column;
 justify-content: center;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
}

h3.first-title {
  font-size: 1.5rem;
  text-align: center;
}

.container-description {
  padding: 1em;
  text-align: center;
}

h6.description {
  font-size: 1rem;
}

.download-title-container {
  align-items: center;
  padding: 2em;
  text-align: center;
}

h3.download-title {
  font-size: 1.5rem;
}

.footer-container {
  padding: 1em;
}

@media (min-width:600px) {
  .container-images {
    display: flex;
    flex-direction: row;
    justify-content: center;
   } 

   h3.first-title {
    font-size: 3rem;
    text-align: center;
  }

  .container-description {
    padding: 2em;
    text-align: center;
  }

  h6.description {
    font-size: 1.5rem;
  }

  .download-title-container {
    align-items: center;
    padding: 2em;
    text-align: center;
  }
}



.image-and-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

p.title {
  margin-top: -2em;
}

.download-app-container {
  display: flex;
  justify-content: center;
}

/* .images-header{
  width: 30vw;
} */